<template>
  <div class="body">
    <section class="banner">
      <h2>矩阵<strong>零号实验室</strong></h2>
      <p>通过对主机厂的<strong>智能化升级</strong>，<br class="mShow">运用核心的技术能力融合<strong>智能化生产</strong><br />快速适应汽车的行业和无人驾驶行业的发展，<br class="mShow">加速快递车量产进程</p>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/technical/m/banner.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/banner.png"
          width="1361" height="797"
          alt="矩阵零号实验室" 
        />
      </picture>
    </section>

    <section class="box">
      <h3>技术能力</h3>
      <ul class="cases">
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_0_1.png" alt="智能化生产" width="246" height="246" />
          <p>智能化<strong>生产</strong></p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_0_2.png" alt="智慧化工程" width="246" height="246" />
          <p>智慧化<strong>工程</strong></p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_0_3.png" alt="工艺模块化" width="246" height="246" />
          <p><strong>工艺</strong>模块化</p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_0_4.png" alt="智能决策管理" width="246" height="246" />
          <p>智能<strong>决策管理</strong></p>
        </li>
      </ul>
    </section>

    <section class="box">
      <h2>矩阵<strong>壹号实验室</strong></h2>
      <p>基于<strong>自动驾驶的核心技术</strong>能力，<br class="mShow">探索外卖场景的<strong>无人配送方案</strong><br />实现端到端打通，落地门到门服务，缩短配送时间，<br class="mShow">提升外卖服务效率和服务品质</p>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/technical/m/lab_1.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/lab_1.png"
          width="1519" height="304"
          alt="矩阵壹号实验室" 
        />
      </picture>
      <h3>技术能力</h3>
      <ul class="cases">
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_1_1.png" alt="智能决策系统" width="246" height="246" />
          <p>智能<strong>决策系统</strong></p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_1_2.png" alt="智能路径规划" width="246" height="246" />
          <p>智能<strong>路径规划</strong></p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_1_3.png" alt="高精定位系统" width="246" height="246" />
          <p>高精<strong>定位系统</strong></p>
        </li>
        <li>
          <img src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/ability_1_4.png" alt="感知系统" width="246" height="246" />
          <p><strong>感知</strong>系统</p>
        </li>
      </ul>
    </section>

    <section class="bannerEnd">
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/technical/m/bannerend.jpg"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/technical/bannerend.jpg"
          width="1920" height="500"
        />
      </picture>
    </section>
  </div>
</template>
<script>
export default {
  name: "Technical",
};
</script>
<style lang="scss" scoped>
.banner {
  margin-bottom: 48px;
  position: relative;
  padding: 252px 0 0;
  height: 1282px;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  background-image: linear-gradient(180deg, #e83e0b 0, #e83e0b 1100px, #fff 1100px);

  h2 {
    margin-bottom: 8px;
    font-size: 100px;
    line-height: 120px;
    color: #fff;
  }

  img {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -710px;
    z-index: 1;
  }
}

.box {
  margin-bottom: 128px;
  padding: 0 50px;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #e83e0b;


  h2, h3 {
    margin-bottom: 10px;
    font-size: 100px;
    line-height: 120px;
    color: #e83e0b;
  }

  h3 {
    margin-bottom: 40px;
    font-size: 60px;
    line-height: 80px;
  }

  img {
    display: block;
    margin: 4px auto 20px;
    max-width: 100%;
    height: auto;
  }

  .cases {
    margin: 0 auto;
    display: flex;
    max-width: 1396px;
    justify-content: space-between;

    li {
      flex: 1;
      margin: 0 5px;
      max-width: 246px;

      p {
        margin-top: 16px;
        font-size: 30px;
        line-height: 40px;
        color: #222226;
      }

      img {
        margin: 0;
      }
    }
  }
}

.bannerEnd {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    margin-bottom: 128px;
    padding-top: 190px;
    height: 640px;
    font-size: 12px;
    line-height: 16px;
    overflow: visible;
    background: #e83e0b;

    h2 {
      margin-bottom: 12px;
      font-size: 45px;
      line-height: 63px;
    }

    img {
      position: static;
      margin: 28px 0 0;
      width: 100%;
      height: auto;
    }
  }

  .box {
    margin-bottom: 24px;
    padding: 0;
    font-size: 12px;
    line-height: 16px;

    h2 {
      margin-bottom: 6px;
      font-size: 45px;
      line-height: 55px;
    }

    h3 {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 40px;
    }

    img {
      margin: 30px auto 72px;
    }

    .cases {
      justify-content: center;
      flex-wrap: wrap;

      li {
        flex: auto;
        margin: 0 24px 40px;
        max-width: 100px;

        p {
          margin-top: 8px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
</style>